.section-barre-recherche {
	
	> .row {
		background: darken($turquoise,10%);
	}

	.container-barre-recherche {		
		
		
			
		
		
	.nav-tabs {
		text-align: center;
		border-bottom: 0 none;
		font-size: 0;
		
		@media(min-width: $grid-float-breakpoint) {
			height: 35px;
		}
		
		> li {
			
			@include transition(all 0.5s);
			
			@media(min-width: $grid-float-breakpoint) {
				//@include transform (translateY(-25px));
				@include transform (translateY(-25%));
			}

			@media(min-width: $grid-float-breakpoint) {
				float: none;
				display: inline-block;
			}

			@media(max-width: $screen-md) {
				width: 25%;
			}

			@media(max-width: $screen-sm) {
				width: 50%;
			}

			@media(max-width: $screen-xs) {
				width: 100%;
			}

			border: 0 none;
			
			@media(min-width: $grid-float-breakpoint) {
				margin: 0 10px;
			}
			
			&:first-child {
				a {
					margin-left: 0;
				}
			}

			&:last-child {
				a {
					margin-right: 0;
				}
			}

			a {
				color: #fff;
				font-size: 18px;
				font-weight: 600;
				text-transform: uppercase;
				padding: 15px 45px;
				//@include opacity(0.5);
				margin: 0 1px;
				border: 0 none;
				
				@media(max-width: $screen-sm) {
					margin: 0;
				}
				
				&:hover {
					color: #fff;
				}
			}
			
			&.tab-acheter {
				a {
					background: $orange;
				}
			}

			&.tab-louer {
				a {
					background: $orange;
				}
			}

			&.tab-investir {
				a {
					background: $orange;
				}
			}

			&.tab-entreprise {
				a {
					background: $orange;
					
					span {
						@media(max-width: $screen-md) {
							display: none;
						}
					}
				}
				
			}
			
			&.active {

				@media(min-width: $grid-float-breakpoint) {
					//@include transform (translateY(-30px));
					@include transform (translateY(-75%));
				}
				
				a {
					border: 0 none;
					position: relative;
					text-decoration: underline;
					/*&:before {
						content: url('../images/traits_blancs_menu.png');
						//margin-right: 10px;
						vertical-align: middle;
						position: absolute;
						left: 15px;
						top: 50%;
						@include transform(translateY(-50%));
					}*/
					//@include opacity(1);
				}
			}
		}
	}
	
	.tab-content {
		padding: 15px 15px;
		
		@media(min-width: $screen-md) {
			padding: 30px 0;
		}
		
		text-align: center;

		.select2-selection__rendered {
			text-align: left;
		}

	}

	.btn-container {
		display: inline-block;
		
		@media(max-width: $screen-md) {
			display: block;
		}
		
		@media(min-width: $screen-md) {
			float: right;
		}
		
		button {
			background: transparent;
			border: 0 none;
			box-shadow: none;
			
			i {
				color: #fff;
				font-size: 26px;
			}
		}
	}

	
	.field-container {
		
		width: 45%;
		
		&:nth-child(2) {
			display: none;
		}

		@media(max-width: $screen-sm) {
			width: 100%;
			margin-bottom: 15px;
		}

		display: inline-block;
		padding: 0 5px;
		
		input {
			//background: #eee;
			//color: $orange;
			//border: 0 none;
			@include box-shadow(none);
			font-size: 14px;
			
		}
		
		.select2 {
			

			 .select2-selection {
				border: 0px none;
				border-radius: 0;
				background: #fff;
				
				.select2-selection__choice {
					background: $vert;
					color: #fff;
					border-radius: 0;
					border: 0px none;
					
					span {
						color: #fff;
					}
				}
				
				.select2-results__options {
					.select2-results__option--highlighted {
						background: $vert;
					}
				}
			}
			
		}
		

		
	}
	
	#tab-acheter {
		
		.select2 {
			.select2-selection__placeholder {
				color: $orange;
			}
		}
		
		.prix-max {
			background: $orange;
			color: #fff;

			&::-webkit-input-placeholder {
				color: #fff;
			}
			&::-moz-placeholder {
				color: #fff;
			}
			&:-ms-input-placeholder {
				color: #fff;
			}
			&:-moz-placeholder {
				color: #fff;
			}
		}
		
		
		.btn-container i.fa-search {
			&:before {
				color: $orange;
			}
		}
		
		input {
			
			&::-webkit-input-placeholder {
				color: $orange;
			}
			&::-moz-placeholder {
				color: $orange;
			}
			&:-ms-input-placeholder {
				color: $orange;
			}
			&:-moz-placeholder {
				color: $orange;
			}
		}
	}

	#tab-louer {
		
		.select2 {
			.select2-selection__placeholder {
				color: $orange;
			}
		}

		.prix-max {
			background: $orange;
			color: #fff;

			&::-webkit-input-placeholder {
				color: #fff;
			}
			&::-moz-placeholder {
				color: #fff;
			}
			&:-ms-input-placeholder {
				color: #fff;
			}
			&:-moz-placeholder {
				color: #fff;
			}
		}

		.btn-container i.fa-search {
			&:before {
				color: $orange;
			}
		}
		input {
			&::-webkit-input-placeholder {
				color: $orange;
			}
			&::-moz-placeholder {
				color: $orange;
			}
			&:-ms-input-placeholder {
				color: $orange;
			}
			&:-moz-placeholder {
				color: $orange;
			}
		}
	}

	#tab-entreprise {
		
		.select2 {
			.select2-selection__placeholder {
				color: $orange;
			}
		}
		
		.prix-max {
			background: $orange;
			color: #fff;

			&::-webkit-input-placeholder {
				color: #fff;
			}
			&::-moz-placeholder {
				color: #fff;
			}
			&:-ms-input-placeholder {
				color: #fff;
			}
			&:-moz-placeholder {
				color: #fff;
			}
		}
		
		.btn-container i.fa-search {
			&:before {
				color: $orange;
			}
		}
		input {
			
			&::-webkit-input-placeholder {
				color: $orange;
			}
			&::-moz-placeholder {
				color: $orange;
			}
			&:-ms-input-placeholder {
				color: $orange;
			}
			&:-moz-placeholder {
				color: $orange;
			}
		}
	}

	&.bien-investissement {
		#tab-acheter {
			
			.select2 {
				.select2-selection__placeholder {
					color: $orange;
				}
			}
			
			.prix-max {
				background: $orange;
				color: #fff;
	
				&::-webkit-input-placeholder {
					color: #fff;
				}
				&::-moz-placeholder {
					color: #fff;
				}
				&:-ms-input-placeholder {
					color: #fff;
				}
				&:-moz-placeholder {
					color: #fff;
				}
			}
			
			
			.btn-container i.fa-search {
				&:before {
					color: $orange;
				}
			}
			
			input {
				
				&::-webkit-input-placeholder {
					color: $orange;
				}
				&::-moz-placeholder {
					color: $orange;
				}
				&:-ms-input-placeholder {
					color: $orange;
				}
				&:-moz-placeholder {
					color: $orange;
				}
			}
		}
	}

	}


}