.section-tabs-ressource {
	.tab-content {
		@include tab-content-resource;
	}
	
	.nav-tabs {
		
		> li {
			margin-right: 15px;
			-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
			-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
			
			> a {
				//background: transparent
				//padding-left: 30px;
				//padding-right: 30px;
				width: 150px;
				text-align: center;
				margin: 0;
				//padding: 0;
				text-transform: uppercase;
				
			}
			
			&.active {
				//background: $violet;
			}
			
			@media(min-width: $screen-sm) {
				border-top-left-radius: 20px;
				border-top-right-radius: 20px;
				
				> a {
					border-top-left-radius: 20px;
					border-top-right-radius: 20px;
				}
			}
			
			@media(max-width: $screen-sm) {
				display: block;
				width: 100%;
				
				> a {
					display: block;
					width: 100%;
				}
			}
		}
	}
}

.archive.post-type-archive-ressource {
	.ligne_resource {
		padding: 15px 0;
				
		&:nth-child(even) {
			background: #eee;
		}
	}
	
		.row-resource {
			margin-bottom: 15px;
			position: relative;
			
			.bordure {
				border: 1px solid $bleu-fonce;
				
				img {
					padding-top: 5px;
					padding-bottom: 5px;
				}
			}
			
			.icone {
				line-height: 50px;
				background: $bleu-fonce;
				width: 50px;
				height: 50px;
				text-align: center;
				border-radius: 50px;
				
				@media(max-width: $screen-md) {
					margin: 0 auto 15px auto;
				}
				
				@media(min-width: $screen-md) {
					position: absolute;
					right: 0;
					top: 0;
				}

				&.icone-lien {
					
					&:before {
					font-family: "FontAwesome";
					content: "";
					color: #fff;
					font-size: 20px;
					}					
				}
	
				&.icone-video {
					&:before {
					font-family: "FontAwesome";
					content: "";
					color: #fff;
					font-size: 20px;
					}					
				}
	
				&.icone-doc {
					&:before {
					font-family: "FontAwesome";
					content: "";
					color: #fff;
					font-size: 20px;
					}					
				}
			}
				

			h3 {
				//color: $violet;
				text-transform: uppercase;
				font-size: 16px;
				margin-top: 0;
			}
			
			.date {
				color: $bleu-fonce;
				font-weight: 500;
			}
			
				@media(max-width: $screen-md) {
					.content,.date,h3,.container-btn {
						text-align: center;
					}
					h3 {
						margin-top: 15px;
					}
				}

		}


}