a, .btn {
	@include transition (all 0.3s);
}

img.img-center {
	margin: 0 auto;
}

.margin-bottom-85 {
	margin-bottom: 85px;
}

.h1 {
	font-size: $font-size-h1;
	font-weight: $headings-font-weight;
}
.h2 {
	font-size: $font-size-h2;
	font-weight: $headings-font-weight;
}
h3,.h3 {
	font-size: $font-size-h3;
	font-weight: 300;
}
h4,.h4 {
	font-size: $font-size-h4;
	font-weight: 700;
}

i.fa {
	&.medium {
		font-size: 30px;
	}
}
.fond-turquoise {
	background-color: $turquoise;
	
	&.fond-active {
		background-color: darken($turquoise,10%);
	}
}
.fond-jaune {
	background-color: $jaune;
}
.fond-vert {
	background-color: $vert;
}
.fond-gris-fonce {
	background-color: $gris-fonce;
}
.fond-gris-clair {
	background-color: $gris-clair;
}
.fond-vert {
	background: $vert;
	position: relative;
	
	&.bi-color {
		&:before {
			position: absolute;
			width: 50%;
			height: 100%;
			right: 0;
			top: 0;
			bottom: 0;
			background: #6ecacc;
			display: block
		}
	}
}

.marge-bas {
	padding-bottom: 35px;
}
.marge-haut {
	padding-top: 35px;
}

.texte-blanc {
	color: #fff;
	h1,h2,h3,h4, ol li, ul li {
		color: #fff;
	}
}
.texte-gris-fonce {
	color: $gris-fonce;
	h1,h2,h3,h4, ol li, ul li {
		color: $gris-fonce;
	}
}
.texte-bleu-fonce {
	color: $bleu-fonce !important;
	h1,h2,h3,h4, ol li, ul li {
		color: $bleu-fonce !important;
	}
}

.texte-violet {
	color: $violet !important;
	h1,h2,h3,h4, ol li, ul li {
		color: $violet !important;
	}
}


hr {
	width: 100px;
	border-width: 3px;
	border-color: #dfdbe1;
	
	&.blanc {
		border-color: #fff;
	}
	
	&.vert {
		border-color: $vert;
	}
}

.wpb_text_column {
	.wpb_wrapper {
		p, ol li, ul li {
			//line-height: 2em;
		}
	}
}

.v-center-img {
	position: relative;
	
	@media(min-width: $screen-sm) {
		img {
			position: absolute;
			top: 50%;
			left: 0;
			@include transform(translateY(-50%));
		}
	}
}

.container-flex-row-sm {
	@media(min-width: $screen-sm) {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		flex-direction: row;
	}
}
.owl-carousel {
	position: relative;
	.owl-next {
		position: absolute;
		top: 50%;
		@include transform(translateY(-50%));
		left: -15px;
	}
	.owl-prev {
		position: absolute;
		top: 50%;
		@include transform(translateY(-50%));
		right: -15px;
	}
}

.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; }
.embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }


.bordure-gauche {
	
	@media(min-width: $screen-md) {
		border-left: 1px solid $gris-fonce;
		padding-left: 15px;
		
		> .vc_column-inner  {
			padding-left: 30px;
		}
	}
}

.bloc-texte {
	ul {
		list-style: none;
		
		li {
			&:before {
				content: "";
				font-family: "FontAwesome";
				color: $turquoise;
				margin-right: 10px;
			}
		}
	}
}

.padding-30 {
	padding-left: 30px;
	padding-right: 30px;
}

.section-breadcrumb {
	color: #fff;
	
	
	>.row {
		background: $turquoise;
		padding-top: 10px;
		padding-bottom: 10px;
		
		span {
			color: #fff;
			
			span, a {
				color: #fff;
			}
		}
	}
	
}

body.sidebar-primary {
	.section-breadcrumb {
		> .row {
			margin-left: 0;
			margin-right: 0;
		}
	}
}
