
#gmap {
	width: 100%;
	height: 500px;
	
	
     /*style the box*/  
     .gm-style .gm-style-iw {
        background-color: #fff !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
        min-height: 150px !important;
        padding-top: 10px;
        display: block !important;
        //border-radius: 10px;
     }    
 
     /*style the p tag*/
     .gm-style .gm-style-iw #google-popup p{
        padding: 10px;
     }
     
 
    /*style the arrow*/
    .gm-style div div div div div div div div {
        background-color: #fff !important;
        padding: 0;
        margin: 0;
        padding: 0;
        top: 0;
        color: #fff;
        font-size: 16px;
    }
    
    /*style the link*/
    .gm-style div div div div div div div div a {
        color: #f1f1f1;
        font-weight: bold;
    }

	.gm-style .gm-style-iw p {
		padding: 0 10px;
	}    

    .gm-style .gm-style-iw p.titre {
		font-weight: 700;
		text-transform: uppercase;
		font-size: 13px;
		line-height: 16px;
		color: $turquoise;
		width: 250px;
    }

    .gm-style .gm-style-iw p.resume {
		font-weight: 300;
		font-size: 13px;
		line-height: 16px;
		width: 100%;
    }


    .gm-style .gm-style-iw p.page-garage {
		font-weight: 300;
		font-size: 14px;
		
		a {
			background: #fff;
			border-radius: $btn-border-radius-base;
			color: $text-color;
			font-weight: 500;
			text-transform: uppercase;
			padding: 5px 8px;
			font-size: 13px;
			opacity: 0.9;
			
			&:hover {
				opacity: 1;
			}
			
		
			&:before {
				content: "";
				font-family: "FontAwesome";
				color: $text-color;
				margin-right: 5px;
			}

		}
    }


}