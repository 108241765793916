.section-news, .archive, .section-types-thematiques, .tax-thematique, .search-results {
	article {
		@include ombre;
		//@media(max-width: $screen-sm) {
			margin-bottom: 35px;
		//}
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		border-bottom: 16px solid;
		
		
		background: #fff;
		
		.image, .image img {
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}
		
		h2,h3 {
			text-transform: uppercase;
			font-size: 16px;
			font-weight: 700;
			text-align: center;
			color: $gris-fonce;
			
			a {
				color: $gris-fonce;
				&:hover {
					text-decoration: none;
				}
			}
		}
		
		.content {
			padding: 15px;
			
			
		}
		
		.container-btn {
			text-align: center;
			
			a {
				//@include transform(translateY(50%));
			}
		}
	}
}

.section-types-thematiques, .tax-thematique {
	article {
		h2 {
			font-size: 18px;
			text-transform: uppercase;
			font-weight: 700;
		}
		.resume {
			font-size: 12px;
		}
	}
}

.tax-thematique {
	.term-description {
		p {
			text-align: center;
		}
	}
}

.single-post {
	article {
		
		margin-top: 35px;
		
		.image {
			position: relative;
			
			img {
				border-top-right-radius: 20px;
				border-top-left-radius: 20px;
			}

			.date {
				position: absolute;
				background: $vert;
				color: #fff;
				text-align: center;
				padding: 5px 10px;
				bottom: 0;
				left: 0;
				line-height: $font-size-base;
				text-transform: uppercase;
				
				span {
					font-weight: 700;
					display: block;
					text-align: center;
					font-size: 26px;
					line-height: 26px;
				}
			}
		}
	
	}
}

.archive.category,  .search-results {
	article {
		
		.type-post {
			font-style: italic;
			font-weight: 300;
			display: block;
			margin-bottom: 5px;
			font-size: 12px;
		}
		
		.image {
			position: relative;
			
			.date {
				position: absolute;
				background: $vert;
				color: #fff;
				text-align: center;
				padding: 5px 10px;
				bottom: 0;
				left: 0;
				line-height: $font-size-base;
				text-transform: uppercase;
				
				span {
					font-weight: 700;
					display: block;
					text-align: center;
					font-size: 26px;
					line-height: 26px;
				}
			}
		}
	
	}
}