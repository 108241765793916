.navbar-collapse {
	@media(min-width: $grid-float-breakpoint) {
		.navbar-nav {
			float: none;
			text-align: center;
			
			> li {
				float: none;
				display: inline-block;
			}
		}
	}
}

header.banner {
	background: #fff center top url('../images/bg-header.jpg');
	background-repeat: no-repeat;
	//background-size: 50%;
	position: relative;
	
	> .container {
		position: relative;
		
	}
	
	.container-nav {
		@media(min-width: $grid-float-breakpoint) and (max-width: $screen-lg) {
			width: auto;
		}
	}
	
	.container-search {
		@media(max-width: $grid-float-breakpoint) {
			display: none;
		}
		
		position: absolute;
		right: 0;
		top: 50%;
		@include transform(translateY(-50%));
		z-index: 1;
		
		.line-search {
			background: #fff;
			text-align: right;
			-webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
			-moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
			box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
			width: 300px;
			
			& > input[type=text] {
				//padding: 15px 25px;
				display: inline-block;
				width: calc(100% - 60px);
				border: 0 none;
				box-shadow: none;
				color: rgb(65, 64, 66);
			}
			
			button[type=submit] {
				display: inline-block;
				padding: 8px 10px;
				background: $gris-fonce;
				i {
					font-size: 26px;
				}
			}
		}
	}
	
	.coin {
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		img {
			display: block;
			max-width: 130px;
		}
	}
	
	img.logo {
		max-width: 300px;
		//height: auto;
		padding: 15px 0;
		display: inline-block;
		@media(max-width: $grid-float-breakpoint) {
			display: none;
		}
	}
	
	.logo-mobile {
		max-width: 200px;
		//height: auto;
		padding: 15px;
		@media(min-width: $grid-float-breakpoint) {
			display: none;
		}
	}

	button.navbar-toggle {
		margin-top: 15px;
		
		  border: none;
		  background: transparent !important;
		
		  &:hover {
		    background: transparent !important;
		  }
		
		  .icon-bar {
		    width: 22px;
		    @include transition(all 0.2s);
		  }
		  .top-bar {
		    transform: rotate(45deg);
		    transform-origin: 10% 10%;
		  }
		  .middle-bar {
		    opacity: 0;
		  }
		  .bottom-bar {
		    transform: rotate(-45deg);
		    transform-origin: 10% 90%;
		  }

		  &.collapsed {
			  	.top-bar {
			  		transform: rotate(0);
				}
			  .middle-bar {
			    opacity: 1;
			  }
			  .bottom-bar {
			    transform: rotate(0);
			  }
		}
	}		

	.navbar-default .navbar-collapse, .navbar-default .navbar-form {
		//@include degrade-gris;
	}
	
	.navbar-header {
		@media(max-width: $grid-float-breakpoint) {
			text-align: center;
			
			> a {
				display: inline-block;
			}
		
			button {
				position: absolute;
				left: 15px;
				top: 10px;
			}
		}
	}
	
	.navbar-default {
		border-bottom: 0 none !important;
	}
	
	nav {
		
		.container-form-mobile {
			
			> li {
				padding: 0 5px;
				.wrapper {
					border: 1px solid $gris-clair;
				}
			}
		}
		
		&.is_stuck {
			-webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
			-moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
			box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
		}
		
		ul.navbar-nav {
			font-size: 0;
			
			>li {
				font-size: $font-size-small;
				font-weight: 700;
				
				> a {
					text-transform: uppercase;
					background: $turquoise;
					
				}
				
				&.current-post-ancestor, &.current-menu-parent, &.current-post-parent {
					> a {
						background: darken($turquoise,10%);
					}
				}
				
				@media(min-width: $grid-float-breakpoint) {
					width: 20%;
					padding-right: 10px;
					
					&:last-child {
						padding-right: 0;
					}

					> a {
						border-top-left-radius: 10px;
						border-top-right-radius: 10px;
					}

				}
				
				@media(max-width: $grid-float-breakpoint) {
					margin-top: 5px;
					> a {
					}
				}
				
				> a {
					color: #fff;
				}
				
				
				&.onglet-violet {
					//padding: 0 5px;
					color: #fff;
					> a {
						//background: $violet;
					}
				}
				&.onglet-rouge {
					//padding: 0 5px;
					color: #fff;
					> a {
						//background: $rouge;
					}
				}
				&.onglet-orange {
					//padding: 0 5px;
					color: #fff;
					> a {
						//background: $orange;
					}
				}
				&.onglet-jaune {
					//padding: 0 5px;
					color: #fff;
					> a {
						//background: $jaune;
					}
				}
				&.onglet-vert {
					//padding: 0 5px;
					color: #fff;
					> a {
						//background: $vert-clair;
					}
				}
				
			}
		}
	}
}

.section-home-recherche {
	
	> .row {
		background-image: url('../images/bg-search.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}
	
	.line-search {
		background: #fff;
		text-align: right;
		-webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
		-moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
		box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
	}

	button.open-menu {
		text-align: left;
		position: relative;
		background: #fff;
		
		&:after {
			position: absolute;
			display: block;
			background: $gris-fonce;
			content: "";
			font-family: "FontAwesome";
			line-height: 56px;
			font-size: 28px;
			color: #fff;
			text-align: center;
			height: 100%;
			right: 0;
			top:0;
			width: 60px;
		}
	}

}

body.sidebar-primary {
	.section-home-recherche {
		> .row {
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.menu-container {

	.menu-collapse {
		background: #fff;
		//padding: 15px;
		
		>.wrapper {
			
			
			ul {
				margin-bottom: 0;
				> li {
					//position: relative;
					padding: $padding-large-vertical $padding-large-horizontal;
					@include transition(all 0.3s);
					cursor: pointer;
					
					> a {
						&:hover {
							text-decoration: none;
						}
					}
					
					&:hover {
						background: #eee;
					}
					&:before {
						//left: -15px;
						content: "";
						font-family: "FontAwesome";
						margin-right: 10px;
					}
				}
			}
		}
	}

   @media screen and (min-width: 768px)
    {
        .collapsing
        {
            position: absolute !important;
            z-index: 20;
            width: 100%;
            width: calc(100% - 30px);
            //top: 0;
        }
        .collapse.in {
            display: block;
            position: absolute;
            z-index: 20;
            width: 100%;
            width: calc(100% - 30px);
            //top: 0;
        }
        .navbar-collapse
        {
            max-height: none !important;
        }
    }

}

//Focus des menus
.single-entreprise, .entreprise-template-default {
	.onglet-rouge > a {
		background: darken($turquoise,10%) !important;
	}
}

.post-type-archive-ressource {
	.onglet-jaune > a {
		background: darken($turquoise,10%) !important;
	}
}