.single-etape {
	.section-bien-map {
		
		> .row {
			border-bottom-width: 10px;
			border-bottom-style: solid;
		}
	}
}

.section-icones-ancres {
	> .row {
		background: $bleu-fonce;
		padding: 15px 0;
		
		ul.liste-ancres {
			text-align: center;
			margin-bottom: 0;
			font-size: 0;
			
			> li {
				display: inline-block;
				padding: 15px;
				background: transparent;
				font-size: $font-size-base;
				width: 25%;
				vertical-align: top;
				
				@media(max-width: $screen-md) {
					width: 50%;
				}
				
				&:hover {
					> .wrapper {
						background: #3d3982;
					}
				}
				
				> .wrapper {
					border: 1px solid #fff;
					border-radius: 20px;
					padding: 15px;
					@include transition(all 0.5s);					

					a {
						display: block;
						&:hover {
							text-decoration: none;
						}
					}
					
					p {
						background: #fff;
						text-align: center;
						text-transform: uppercase;
						padding: 5px 15px;
					}
					
				}
				
				@media(max-width: $screen-xs) {
					display: block;
					width: 100%;
					
					> .wrapper {
						a {
							text-align: left;
						}
						img {
							max-height: 30px;
							width: auto;
							display: inline-block;
						}
						p {
							display: inline-block;
							margin-bottom: 0;
							background: transparent;
							color: #fff;
						}
					}
				}
				
			}
		}
	}
}

.section-tabs-ressource {
	
	
	> .row {
		background: $gris-clair;
		padding: 35px 0;
	}
}

.section-retour {
	> .row {
		padding: 35px;
	}
}

.section-descriptif-thematique {
	> .row {
		background: $gris-clair;
	}
	
	.bg-fill {
		background-size: cover;
		background-position: center center;
	}
}

.section-synthese {
	> .row {
		.bloc-texte {
			h3 {
				color: $bleu-clair;
				text-transform: uppercase;
				font-size: 16px;
				font-weight: 700;
			}
		}
	}
}

.section-pictos {
	.picto {
		width: 115px;
		height: 115px;
		margin: 0 auto;
		padding: 30px;
		border-radius: 100px;
		@include ombre;
	}
	.picto-infos {
		text-transform: uppercase;
		font-size: 20px;
		text-align: center;
		font-weight: 400;
		margin-top: 15px;
	}
}

.section-etape-avenir-descriptif-entreprise {
	> .row {
		background: $gris-clair;
	}
	
	.bg-fill {
		background-size: cover;
		background-position: center center;
	}
	
}


.section-etape-avenir-logos {
	> .row {
		background: $gris-clair;
		padding: 35px 0;
	}
	
	@media(min-width: $screen-md) {
		.organisateurs {
			border-right: 1px solid $gris-fonce;
		}
	}
	
	.liste-logo {
		margin-bottom: 0;
	}
	
	.titre-logos {
		font-weight: 600;
	}
}

.section-etape-programme {
	article {
		margin-bottom: 35px;
		
		.num-etape {
			font-size: 65px;
			text-align: center;
			color: $violet;
			height: 130px;
			width: 130px;
			line-height: 95px;
			font-weight: 400;
			border-radius: 150px;
			border: 15px solid $violet;
			@include ombre;
		}
		
		.horaire {
			color: $violet;
			font-size: 20px;
			margin-bottom: 0;
		}
		
		h3 {
			margin-top: 0;
			text-transform: uppercase;
		}
		
		&:nth-child(1) {
			.num-etape {
				opacity: 0.7;
			}
		}
		&:nth-child(2) {
			.num-etape {
				opacity: 0.75;
			}
		}
		&:nth-child(3) {
			.num-etape {
				opacity: 0.8;
			}
		}
		&:nth-child(4) {
			.num-etape {
				opacity: 0.85;
			}
		}
		&:nth-child(5) {
			.num-etape {
				opacity: 0.9;
			}
		}
		&:nth-child(6) {
			.num-etape {
				opacity: 0.95;
			}
		}

	}
	
	.box-infos-pratiques {
		padding: 15px;
		ul li , p, ol li,h2,h3,h4 {
			color: #fff;
		}
		.titre {
			font-weight: 400;
		}
	}
}

.section-etape-avenir-contact {
	> .row {
		background: $bleu-fonce;
	}
	
	form {
		input[type=text],input[type=tel],input[type=email] {
			border-top: 0 none;
			border-left: 0 none;
			border-right: 0 none;
			color: #fff;
			
			&::-webkit-input-placeholder {
				color: #aaa;
			}
			&::-moz-placeholder {
				color: #aaa;
			}
			&:-ms-input-placeholder {
				color: #aaa;
			}
			&:-moz-placeholder {
				color: #aaa;
			}
		}
		
	}
}