footer.content-info {
  background : $turquoise;
  padding    : 35px 0;

  .logo {
    max-width : 200px;
  }

  ul.logos {
    margin-bottom : 35px;
  }

  section {
    &.widget_nav_menu {
      ul {
        padding    : 0;
        margin     : 0;
        list-style : none;

        > li {
          //text-align: center;
          //text-transform: uppercase;

          > a {
            color : #ffffff;
          }
        }
      }
    }

    &.widget_text {

      i {
        color     : #ffffff;
        font-size : 26px;
        margin    : 0 5px;
      }
    }
  }

  .row-footer-container {
    margin : 0;
    width  : 100%;

    .row-footer {
      display         : flex;
      align-items     : center;
      justify-content : space-between;

      > li {
        vertical-align : top;
      }

      .logo-anact {
        width   : 33%;
        display : inline-block;
        padding : 0 15px;
      }

      .menu-container {
        display         : flex;
        align-items     : flex-start;
        justify-content : space-between;
        padding         : 0 5px;

        .widget {
          margin : 0 5px;
        }

        ul {
          li {
            font-size      : 11px;
            font-weight    : 500;
            text-transform : uppercase;
            text-align     : left;
          }
        }
      }

      .reseaux {
        width   : 10%;
        display : inline-block;
        padding : 0 5px;

        ul {
          li {
            a {
              color     : #ffffff;
              font-size : 20px;
            }
          }
        }
      }

    }

    @media screen and (max-width : 1000px) {
      .row-footer {
        flex-direction : column;

        .menu-container {
          .widget {
            margin : 5px 0;
          }
        }
      }
    }

  }
}
