.section-chiffres-cles {
	position: relative;
	
	&:before {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		display: block;
		content: " ";
		background: $gris-clair;
		z-index: -2;
	}
}

.compteur-chiffre {	

	&.courbe-haut {
		.chiffre:before {
			position: absolute;
			display: block;
			width:335px;
			height:335px; 
			border:solid 25px #000;
			border-color:#000 transparent transparent transparent;
			border-radius: 50%/100px 100px 0 0;
			content: " ";
			top: 0;
			@include transform(translate(-25px,25px));
			z-index: -1;
			left: 50%;
		}
	}
	
	&.courbe-bas {
		.chiffre:before {
			position: absolute;
			display: block;
			width:335px;
			height:335px; 
			border:solid 25px #000;
			border-color:#000 transparent transparent transparent;
			border-radius: 50%/100px 100px 0 0;
			content: " ";
			bottom: 0;
			@include transform(translate(-25px,-25px) rotate(180deg));
			z-index: -1;
			left: 50%;
		}
		
	}
	
	@media(max-width: $screen-lg) {
		&.courbe-haut {
			.chiffre:before {
				width:275px;
				height:275px; 
				border:solid 25px #000;
			}
		}
		
		&.courbe-bas {
			.chiffre:before {
				width:275px;
				height:275px; 
				border:solid 25px #000;
			}
			
		}
	}

	@media(max-width: $screen-md) {
		&.courbe-haut {
			.chiffre:before {
				width:225px;
				height:225px; 
				border:solid 25px #000;
			}
		}
		
		&.courbe-bas {
			.chiffre:before {
				width:225px;
				height:225px; 
				border:solid 25px #000;
			}
			
		}
	}
	
	@media(max-width: $screen-sm) {
		&.courbe-haut {
			.chiffre:before {
				display: none;
			}
		}
		
		&.courbe-bas {
			.chiffre:before {
				display: none;
			}
			
		}
	}

	&.courbe-violet {
		.chiffre:before {
		border-color:darken($violet,10%) transparent transparent transparent;
		}
	}
	&.courbe-rouge {
		.chiffre:before {
		border-color:darken($rouge,10%) transparent transparent transparent;
		}
	}
	&.courbe-jaune {
		.chiffre:before {
		border-color:darken($jaune,10%) transparent transparent transparent;
		}
	}

	&.chiffre-rouge {
		
		.chiffre {
			.chiffre-counter {
				background: $rouge;
			}
		}
	}

	&.chiffre-violet {
		
		.chiffre {
			.chiffre-counter {
				background: $violet;
			}
		}
		
	}

	&.chiffre-vert {
		
		.chiffre {
			.chiffre-counter {
				background: $vert-clair;
			}
		}
	}

	&.chiffre-jaune {
		
		.chiffre {
			.chiffre-counter {
				background: $jaune;
			}
		}
	}

	.chiffre {
		
		position: relative;

		.chiffre-counter {
			font-weight: 700;
			font-size: 50px;
			line-height: 150px;
			height: 155px;
			width: 155px;
			margin: 0 auto;
			color: #fff;
			border-radius: 150px;
			margin-bottom: 15px;
			@include ombre;
		}
		
		.legend {
			position: absolute;
			color: #fff;
			font-weight: 700;
			font-size: 16px;
			bottom: 25px;
			@include transform(translateX(-50%));
			//display: none;
		}
		
		.legend-sub {
			color: #fff;
			font-weight: 700;
			font-size: 16px;
		}
		
		.currency {
			font-size: 20px;
		}
	}
}

