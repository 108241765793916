@mixin degrade-gris {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e6e6e5+0,ffffff+50,e6e6e5+100 */
	background: #e6e6e5; /* Old browsers */
	background: -moz-linear-gradient(left, #e6e6e5 0%, #ffffff 50%, #e6e6e5 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, #e6e6e5 0%,#ffffff 50%,#e6e6e5 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, #e6e6e5 0%,#ffffff 50%,#e6e6e5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6e6e5', endColorstr='#e6e6e5',GradientType=1 ); /* IE6-9 */	
}

@mixin ombre {
	-webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
	-moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
	box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
}

@mixin tab-content-resource {
		background: #fff;
		//border-top-left-radius: $border-radius-large;
		//border-top-right-radius: $border-radius-large;
		-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
		-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
		padding: 35px;
		
		.row-resource {
			margin-bottom: 15px;
			
			h3 {
				//color: $violet;
				text-transform: uppercase;
				font-size: 16px;
				margin-top: 0;
			}
			
			.date {
				color: $bleu-fonce;
				font-weight: 500;
			}
		}
}