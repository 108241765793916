.section-home-recherche {
	> .row {
		
		
		> .bg {
			background: center top url(../images/ombre-ligne.png) no-repeat;
			padding: 110px 0;
		}
	}
	
	.form-title {
		//text-transform: uppercase;
		font-size: 18px;
		font-weight: 300;
	}
	
	@media(max-width: $screen-xs) {
		.menu-container {
			button {
				font-size: 13px;
			}
		}
	}
}

.section-entreprise {
	
	.bg-fill {
		background-position: center center;
		background-size: cover;
	}
	
	.container-resume {
		h2 {
			margin-top: 0;
		}
		
		p {
			margin-bottom: 15px;
		}
	}
}

.section-infos {
	position: relative;
	
	
	@media(max-width: $screen-sm) {
		
		> .vc_column_container > .vc_column-inner {
			padding-left: 0;
			padding-right: 0;
		}
	}
	
	@media(min-width: $screen-sm) {
		&:before {
			position: absolute;
			width: 100%;
			left: -50%;
			top:0;
			display: block;
			background: $bleu-fonce;
			height: 100%;
			bottom: 0;
			content: " ";
		}
	}
	
	.colonne {
		position: relative;
		@media(max-width: $screen-sm) {
			.owl-carousel {
				margin: 15px 0;
			}
		}
		@media(min-width: $screen-sm) {
			.owl-carousel {
				position: absolute;
				top: 80%;
				@include transform(translateY(-50%));
			}
		}
		
		&:first-child {
			background: $bleu-fonce;
		}
	}
	
	.bloc-actu {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		flex-direction: row;
		background: #fff;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		margin-bottom: 20px;
		
		.col-gauche {
			width: 30%;
		}
		
		.bg-fill {
			background-size: cover;
			background-position: center center;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}
		
		.col-droite {
			width: 70%;
			padding: 10px 15px;
			
			.resume {
				font-size: 13px;
			}
			
			.titre {
				margin-top: 0;
				text-transform: uppercase;
			}
		}
	}
}