.section-descriptif-entreprise {
	> .row {
		background: $bleu-fonce;
	}
	
	.bg-fill {
		background-size: cover;
		background-position: center center;
	}
	
}

.section-content-entreprise {
	> .row {
		background: $gris-clair;
		
		.bloc-texte {
			h3 {
				color: $bleu-clair;
				text-transform: uppercase;
				font-size: 16px;
				font-weight: 700;
			}
		}
	}
}

.section-tabs-video-entreprise {
	
	
	> .row {
		//background: $gris-clair;
		padding: 35px 0;
	}
	
	.tab-content {
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
		@include tab-content-resource;
	}
}
