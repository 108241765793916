.btn.btn-turquoise {
	background: $turquoise;
	color: #fff;
	
	&:hover {
		background: darken($turquoise,10%);
	}
}

.btn.btn-blanc {
	background: #fff;
	color: $bleu-fonce;
	&:hover {
		background: darken(#fff,10%);
	}
}

.btn.btn-violet {
	background: $violet;
	color: #fff;
	
	&:hover {
		background: darken($violet,10%);
	}
}